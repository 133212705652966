import "./App.css"
import React, { useMemo, useState } from "react"
import { MaterialReactTable } from "material-react-table"
import { IconButton, Tooltip } from "@mui/material"
import RefreshIcon from "@mui/icons-material/Refresh"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query"
import { Box, Typography } from "@mui/material"
import { useConnectWallet } from "@web3-onboard/react"
import { controllerAddress, controllerABI } from "./controller"
import { redeliveryRelayAddress, redeliveryRelayABI } from "./redeliveryRelay"
import { ethers } from "ethers"
import ReactModal from "react-modal"

/* global BigInt */

// Logging?
const logging = true

const arrngOracle = "0x24a088AC51871780fCc4c3b02FAfB0b4e0C98B4e"

const gasCalcSafetyMargin = 133
const gasPriceSafetyMargin = 150
const ethFee = "1000000000000000"
const maticTestnetFee = "2000000000000000"
const maticMainnetFee = "2000000000000000000"

// Deterime priority fee:
const priorityFeeState = {
  1: 1000000000, // mainnet 1 gwei
  42161: 100000000, // arbitrum one 0.1 gwei
  10: 100000000, // optimism 0.1 gwei
  137: 30000000000, // polygon 30 gwei
  56: 0, // BSC
  // Testnet APIs
  5: 1000000000, // goerli 1 gwei
  11155111: 1000000000, // sepolia 1 gwei
  421613: 100000000, // arbitrum goerli 0.1 gwei
  420: 100000000, // optimism goerli 0.1 gwei
  80001: 2000000000, // polygon mumbai 2 gwei
  97: 0, // BSC Testnet
  11145513: 100000000, // blessnet sepolia 0.1 gwei
  45513: 100000000, // blessnet 0.1 gwei
}

// Determine arrng fee:
const feeForChainState = {
  1: ethFee, // mainnet 1 gwei
  42161: ethFee, // arbitrum one 0.1 gwei
  10: ethFee, // optimism 0.1 gwei
  137: maticMainnetFee, // polygon 30 gwei
  56: ethFee, // BSC
  // Testnet APIs
  5: ethFee, // goerli 1 gwei
  11155111: ethFee, // sepolia 1 gwei
  421613: ethFee, // arbitrum goerli 0.1 gwei
  420: ethFee, // optimism goerli 0.1 gwei
  80001: maticTestnetFee, // polygon mumbai 0.1 gwei
  97: ethFee, // BSC Testnet
  11145513: ethFee, // blessnet sepolia 1 gwei
  45513: ethFee, // blessnet 1 gwei
}

// Deterime gas multiplier:
const gasMultiplierState = {
  mulGasComplexity0: 100,
  mulGasComplexity1: 150,
  mulGasComplexity2: 200,
  mulGasComplexity3: 250,
}

const ArrscanTable = () => {
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilter, setGlobalFilter] = useState("")
  const [sorting, setSorting] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  })

  // Error modal handlers:
  const openErrorModal = (message) => {
    setErrorModalOpen(true)
    setErrorMessage(message)
  }

  const closeErrorModal = () => {
    setErrorModalOpen(false)
  }

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      //pagination.pageIndex, //refetch when pagination.pageIndex changes
      //pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL("https://api.arrng.io/getArrscanData")
      // fetchURL.searchParams.set(
      //   "start",
      //   `${pagination.pageIndex * pagination.pageSize}`,
      // )
      // fetchURL.searchParams.set("size", `${pagination.pageSize}`)
      // fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []))
      // fetchURL.searchParams.set("globalFilter", globalFilter ?? "")
      // fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []))

      console.log(fetchURL.href)

      const response = await fetch(fetchURL.href)
      const json = await response.json()
      console.log(json)
      return json
    },
    keepPreviousData: true,
  })

  const columns = useMemo(
    () => [
      {
        accessorKey: "chain",
        header: "Chain",
        filterFn: "equals",
        size: 120,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              alt="chain"
              height={30}
              src={`${row.original.chain}.svg`}
              loading="lazy"
              style={{ borderRadius: "10%" }}
            />
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "request",
        header: "ID",
        size: 60,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 120,
      },
      {
        accessorKey: "fromAddress",
        header: "Request From",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                flexGrow: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {renderedCellValue}
            </span>
            <Tooltip title="Copy to Clipboard">
              <IconButton
                size="small"
                onClick={() => handleCopyToClipboard(renderedCellValue)}
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "refundAddress",
        header: "Refund Address",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                flexGrow: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {renderedCellValue}
            </span>
            <Tooltip title="Copy to Clipboard">
              <IconButton
                size="small"
                onClick={() => handleCopyToClipboard(renderedCellValue)}
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "requestTimestamp",
        header: "Requested At",
      },
      {
        accessorKey: "servedTimestamp",
        header: "Served At",
      },
      {
        accessorKey: "requestTxnHash",
        header: "Request Txn Hash",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                flexGrow: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {renderedCellValue}
            </span>
            <Tooltip title="Copy to Clipboard">
              <IconButton
                size="small"
                onClick={() => handleCopyToClipboard(renderedCellValue)}
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "servedTxnHash",
        header: "Serve Txn Hash",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                flexGrow: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {renderedCellValue}
            </span>
            <Tooltip title="Copy to Clipboard">
              <IconButton
                size="small"
                onClick={() => handleCopyToClipboard(renderedCellValue)}
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [],
  )

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
  }

  // Wallets and providers:
  const [{ wallet }, connect] = useConnectWallet()

  // Get the cost of the call in native token:
  async function getCost(
    provider,
    complexity,
    numbers,
    response,
    signature,
    uniqueId,
  ) {
    // Get the chain:
    const chainId = (await provider.getNetwork()).chainId

    // Get the gas units for the response to this RNG request:
    const contract = new ethers.Contract(
      controllerAddress,
      controllerABI,
      provider,
    )

    // Get the gas limit:
    const gasLimit = BigInt(
      await contract.serveRandomness.estimateGas(
        uniqueId,
        ethers.ZeroAddress,
        ethers.ZeroHash,
        0,
        numbers,
        ethers.ZeroAddress,
        response,
        signature,
        1,
        { from: arrngOracle, value: 1 },
      ),
    )

    if (logging) console.log("gasLimit", gasLimit)

    // Determine complexity multiplier:
    const mulGasComplexity = BigInt(
      gasMultiplierState["mulGasComplexity" + complexity],
    )

    // Determine the fee:
    const feeForChain = BigInt(feeForChainState[chainId])

    if (logging) console.log("feeForChain", feeForChain)

    // Get the current gas price from ethers:
    const gasPriceWei = BigInt((await provider.getFeeData()).gasPrice)

    if (logging) console.log("gasPriceWei", gasPriceWei)

    // Get the priority fee for this chain:
    const priorityFee = BigInt(priorityFeeState[chainId])

    if (logging) console.log("priorityFee", priorityFee)

    // Add the priority fee to the returned gas price:
    const gasPriceWithPriority = gasPriceWei + priorityFee

    if (logging) console.log("gasPriceWithPriority", gasPriceWithPriority)

    // Apply compexity modifier:
    const gasLimitWithComplexityModifier =
      (gasLimit * mulGasComplexity) / BigInt("100")

    // Apply safety margin to both gas unit calc and price:
    const safetyGasLimit =
      (gasLimitWithComplexityModifier * BigInt(gasCalcSafetyMargin)) /
      BigInt("100")
    const safetyGasPrice =
      (gasPriceWithPriority * BigInt(gasPriceSafetyMargin)) / BigInt("100")

    if (logging) console.log("safetyGasLimit", safetyGasLimit)
    if (logging) console.log("safetyGasPrice", safetyGasPrice)

    // Total cost is (gas units * gas limit) + fee:
    let calculatedCost = safetyGasPrice * safetyGasLimit + feeForChain

    return calculatedCost
  }

  const chainNames = {
    1: "ethereum",
    42161: "arbitrum one",
    10: "optimism",
    137: "polygon",
    56: "bsc",
    5: "goerli",
    11155111: "sepolia",
    421613: "arbitrum goerli",
    420: "optimism goerli",
    80001: "polygon mumbai",
    97: "bsc testnet",
    11145513: "bless sepolia",
    45513: "blessnet"
  }

  const handleRedelivery = async (
    uniqueId,
    chainId,
    numbers,
    apiResponse,
    apiSignature,
  ) => {
    // Connect the wallet
    if (!wallet) {
      await connect()
    } else {
      console.log(wallet)

      const provider = new ethers.BrowserProvider(wallet.provider, "any")

      // Get the chain:
      const walletChainId = (await provider.getNetwork()).chainId

      console.log(chainId)
      console.log(walletChainId)

      if (Number(chainId) !== Number(walletChainId)) {
        openErrorModal(`Switch network to ${chainNames[chainId]}`)
      } else {
        // Get the required cost
        try {
          const calculatedCost = await getCost(
            provider,
            2,
            numbers,
            apiResponse,
            apiSignature,
            uniqueId,
          )

          const signer = await provider.getSigner()

          const contractWithSigner = new ethers.Contract(
            redeliveryRelayAddress,
            redeliveryRelayABI,
            signer,
          )

          // Make the contract call
          await contractWithSigner
            .connect(signer)
            ["requestRedelivery(uint256)"](uniqueId, {
              value: calculatedCost,
            })
        } catch (error) {
          console.error("Error retrieving gas price:", error)
          // Handle the error appropriately
        }
      }
    }
  }

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={data ?? []} //data is undefined on first render
        enableColumnResizing
        enableStickyHeader
        enableStickyFooter
        initialState={{ showColumnFilters: true, density: "compact" }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderTopToolbarCustomActions={() => (
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}
        rowCount={data?.length ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
        renderDetailPanel={({ row }) => (
          <Box
            sx={{
              display: "grid",
              margin: "auto",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "10px", // Add padding between rows
              width: "100%",
            }}
          >
            {/* Nested grid for Random Numbers */}
            <div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    marginRight: "5px",
                    fontSize: "0.875rem",
                  }}
                >
                  <strong>Random Numbers:</strong>
                </Typography>
                <Tooltip title="Copy to Clipboard">
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleCopyToClipboard(row.original.randomNumbers)
                    }
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              {/* Data */}
              <Typography
                style={{
                  maxWidth: "450px",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  fontSize: "0.875rem",
                  gridColumn: "span 2", // Expand the data to span 2 columns
                }}
              >
              {Array.isArray(row.original.randomNumbers) ? row.original.randomNumbers.join(", ") : ''}
              </Typography>
            </div>

            {/* Nested grid for API Response and button */}
            <div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    marginRight: "5px",
                    fontSize: "0.875rem",
                  }}
                >
                  <strong>API Response:</strong>
                </Typography>
                <Tooltip title="Copy to Clipboard">
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleCopyToClipboard(row.original.apiResponse)
                    }
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              {/* Data */}
              <Typography
                style={{
                  maxWidth: "450px",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  fontSize: "0.875rem",
                  gridColumn: "span 2", // Expand the data to span 2 columns
                }}
              >
                {row.original.apiResponse}
              </Typography>
            </div>

            {/* Nested grid for API Signature and button */}
            <div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    marginRight: "5px",
                    fontSize: "0.875rem",
                  }}
                >
                  <strong>API Signature:</strong>
                </Typography>
                <Tooltip title="Copy to Clipboard">
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleCopyToClipboard(row.original.apiSignature)
                    }
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              {/* Data */}
              <Typography
                style={{
                  maxWidth: "450px",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  fontSize: "0.875rem",
                  gridColumn: "span 2", // Expand the data to span 2 columns
                }}
              >
                {row.original.apiSignature}
              </Typography>
            </div>

            <Typography
              style={{
                maxWidth: "450px",
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
                fontSize: "0.875rem",
              }}
            >
              <strong>Method:</strong>{" "}
              {row.original.method === 0
                ? "0: array of uint256"
                : row.original.method === 1
                ? "1: integer in range"
                : "unknown method"}
            </Typography>

            <Typography
              style={{
                maxWidth: "450px",
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
                fontSize: "0.875rem",
              }}
            >
              <strong>Min / Max Value:</strong>{" "}
              {row.original.minValue !== 0 || row.original.maxValue !== 0
                ? `${row.original.minValue} / ${row.original.maxValue}`
                : "not applicable for method"}
            </Typography>

            <Typography
              style={{
                maxWidth: "450px",
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
                fontSize: "0.875rem",
              }}
            >
              <strong>Numbers Requested:</strong>{" "}
              {row.original.numbersRequested}
            </Typography>

            <Typography
              style={{
                maxWidth: "450px",
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
                fontSize: "0.875rem",
              }}
            >
              <strong>Sent Token:</strong> {row.original.sentToken}
            </Typography>

            <Typography
              style={{
                maxWidth: "450px",
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
                fontSize: "0.875rem",
              }}
            >
              <strong>Refunded Token:</strong> {row.original.refund}
            </Typography>

            {/* Nested grid for refund address */}
            <div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    marginRight: "5px",
                    fontSize: "0.875rem",
                  }}
                >
                  <strong>Refund Address:</strong>
                </Typography>
                <Tooltip title="Copy to Clipboard">
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleCopyToClipboard(row.original.refundAddress)
                    }
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              {/* Data */}
              <Typography
                style={{
                  maxWidth: "450px",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  fontSize: "0.875rem",
                  gridColumn: "span 2", // Expand the data to span 2 columns
                }}
              >
                {row.original.refundAddress}
              </Typography>
            </div>

            {/* Nested grid for refunded timestamp */}
            {row.original.refundTimestamp && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      marginRight: "5px",
                      fontSize: "0.875rem",
                    }}
                  >
                    <strong>Refunded At:</strong>
                  </Typography>
                  <Tooltip title="Copy to Clipboard">
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleCopyToClipboard(row.original.refundTimestamp)
                      }
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                {/* Data */}
                <Typography
                  style={{
                    maxWidth: "450px",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    fontSize: "0.875rem",
                    gridColumn: "span 2", // Expand the data to span 2 columns
                  }}
                >
                  {row.original.refundTimestamp}
                </Typography>
              </div>
            )}

            {/* Nested grid for Refund Txn Hash */}
            {row.original.refundTxnHash && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      marginRight: "5px",
                      fontSize: "0.875rem",
                    }}
                  >
                    <strong>Refund Txn Hash:</strong>
                  </Typography>
                  <Tooltip title="Copy to Clipboard">
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleCopyToClipboard(row.original.refundTxnHash)
                      }
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                {/* Data */}
                <Typography
                  style={{
                    maxWidth: "450px",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    fontSize: "0.875rem",
                    gridColumn: "span 2", // Expand the data to span 2 columns
                  }}
                >
                  {row.original.refundTxnHash}
                </Typography>
              </div>
            )}

            {/* Nested grid for button */}
            {row.original.status !== "served" &&
              row.original.status !== "requested" &&
              row.original.status !== "redelivery" &&
              row.original.status !== "serving" && (
                <div style={{ height: "100%", width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <button
                      style={{
                        maxWidth: "450px",
                        height: "60px",
                        width: "100%",
                        display: "inline-block",
                        cursor: "pointer",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        handleRedelivery(
                          row.original.request,
                          row.original.chain,
                          row.original.randomNumbers,
                          row.original.apiResponse,
                          row.original.apiSignature,
                        )
                      }}
                    >
                      {row.original.status === "overdue" ||
                      row.original.status === "sped up!"
                        ? wallet
                          ? "Speed up"
                          : "Processing - Connect wallet for options"
                        : wallet
                        ? "Retry"
                        : "Delivery Failed - Connect wallet for options"}
                    </button>
                  </Box>
                </div>
              )}
          </Box>
        )}
      />
      <ReactModal
        isOpen={errorModalOpen}
        onRequestClose={closeErrorModal}
        contentLabel="Error Modal"
        className="error-modal" // Apply the error-modal class
        overlayClassName="error-modal-overlay" // Apply an overlay class if needed
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.25)", // Change the overlay background color
          },
          content: {
            backgroundColor: "rgba(255, 255, 255, 0.95)", // Change the overlay background color

            border: "none", // Optional: Remove border if needed
            color: "black",
          },
        }}
      >
        <h2 style={{ textAlign: "center" }}>wrong network</h2>
        <p style={{ textAlign: "center" }}>{errorMessage}</p>
        <button
          onClick={closeErrorModal}
          style={{
            backgroundColor: "black", // Change the button background color
            color: "white", // Change the button text color
            border: "none",
            borderRadius: "18px", // Optional: Add border radius
            padding: "8px 12px", // Optional: Adjust padding
            cursor: "pointer",
            fontFamily: "courier",
            fontSize: "11pt",
          }}
        >
          CLOSE
        </button>
      </ReactModal>
    </div>
  )
}

const queryClient = new QueryClient()

const ArrscanTableView = () => (
  <QueryClientProvider client={queryClient}>
    <ArrscanTable />
  </QueryClientProvider>
)

export default ArrscanTableView
